import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/dash": [3],
		"/enrolment-token/[order_id]/[product_id]/generate": [6],
		"/enrolment": [4],
		"/enrolment/[order_id]/[product_id]": [5],
		"/invoices": [7],
		"/notices": [8],
		"/notifications": [9],
		"/orders": [10],
		"/payments": [11],
		"/reporting": [12],
		"/reporting/daily-updates": [13],
		"/reporting/draft-orders": [14],
		"/reporting/exceptions": [15],
		"/reporting/favourited-courses": [16],
		"/reporting/purchases": [17],
		"/reports/orders": [18],
		"/store-front-groups": [19],
		"/store-front-groups/[store_front_group_id]": [20],
		"/teacher": [21],
		"/testimonials": [22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';